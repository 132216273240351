import {
  FETCH_GAMESLIST_BEGIN,
  FETCH_GAMESLIST_FAIL,
  FETCH_GAMESLIST_SUCCESS,
  ADD_GAME,
} from './constants'

import { getWinlistUrl } from '../api'

const fetchGameslistBegin = data => {
  return {
    type: FETCH_GAMESLIST_BEGIN,
    payload: data,
  }
}

const fetchGameslistSuccess = data => {
  return {
    type: FETCH_GAMESLIST_SUCCESS,
    payload: data,
  }
}

const fetchGameslistFail = data => {
  return {
    type: FETCH_GAMESLIST_FAIL,
    payload: data,
  }
}

export const fetchGameslist = listname => {
  return async dispatch => {
    dispatch(fetchGameslistBegin({ listname }))
    try {
      const result = await fetch(getWinlistUrl(listname))
      const text = await result.text()
      const json = await JSON.parse(text)
      dispatch(fetchGameslistSuccess({ listname, data: json }))
    } catch (error) {
      console.error(error)
      dispatch(fetchGameslistFail({ listname }))
    }
  }
}

export const addGameToList = (listname, data) => {
  return {
    type: ADD_GAME,
    payload: { listname, data },
  }
}
