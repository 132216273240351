// @flow
const BASE_URL = 'satoshidice.com'
const SATOSHI_DICE_API_URL = `https://${BASE_URL}/api/`
export const WEBSOCKET_URL = `wss://${BASE_URL}/ws/dice`
//export const TROLLBOX_URL = 'wss:satoshidice.com/api/chat-join'
export const EXPLORER_URL = 'https://explorer.bitcoin.com/bch/tx/'
export const PRICES_URL = 'https://markets.api.bitcoin.com/live/bitcoin'

export const GAMERULES_URL = `${SATOSHI_DICE_API_URL}gamerules`
export const FAIR_URL = `${SATOSHI_DICE_API_URL}provablyfair`

export const getWinlistUrl = listname => {
  return SATOSHI_DICE_API_URL + listname
}

export const getProvablyfairUrl = () => {
  return SATOSHI_DICE_API_URL + 'provablyfair'
}

export const getVerifyUrl = gameId => {
  return SATOSHI_DICE_API_URL + 'game?id=' + gameId
}

export const getExplorerUrl = tx => {
  return EXPLORER_URL + tx
}

export const getWinlist = async listname => {
  try {
    const result = await fetch(getWinlistUrl(listname))
    const text = await result.text()
    const json = await JSON.parse(text)
    return json
  } catch (e) {
    console.error(e)
    return e
  }
}
