import React from 'react'
import styled from 'styled-components'

import Menu from 'components/Menu'
import Footer from 'components/Footer'

import Helmet from 'react-helmet'
import favicon from 'assets/favicon.ico'

import musicSrc from 'assets/stack-my-bch-up.mp3'
import sfxNew from 'assets/smb3_coin.wav'
import sfxWin from 'assets/tada.wav'

const Wrapper = styled.div``

class StackLayout extends React.Component {
  constructor(props) {
    super(props)
    const soundstatus = true // cookies.get('soundstatus') === 'true'

    this.state = {
      soundstatus,
      musicStatus: false,
    }

    this.playSoundNew = this.playSoundNew.bind(this)
    this.playSoundWin = this.playSoundWin.bind(this)
    this.handleToggleMute = this.handleToggleMute.bind(this)
    this.handleToggleMusic = this.handleToggleMusic.bind(this)
  }

  playSoundWin() {
    this.soundWin.play()
  }

  playSoundNew() {
    this.soundNew.play()
  }

  handleToggleMusic() {
    if (this.soundMusic.currentTime === 0) {
      this.soundMusic.play()
      this.setState({
        musicStatus: true,
      })
    } else {
      this.soundMusic.pause()
      this.soundMusic.currentTime = 0
      this.setState({
        musicStatus: false,
      })
    }
  }

  handleToggleMute(e) {
    this.setState({
      soundstatus: !this.state.soundstatus,
    })

    //cookies.set('soundstatus', !this.state.soundstatus, { path: '/' })
  }
  render() {
    const { children, locale } = this.props

    return (
      <Wrapper>
        <Helmet>
          <title>
            Satoshi Dice Bitcoin Games | Bitcoin Dice Game | SatoshiDICE
          </title>
          <meta
            name="description"
            content="Play Satoshi Dice bitcoin games, the epic BTC dice game is a provably fair on-chain Bitcoin Cash game. Take on the ghost of Satoshi, roll the dice to win."
          />
          <meta
            name="keywords"
            content="bitcoin games, bitcoin dice, btc dice, satoshi dice, satoshi bet, top bitcoin games, game bitcoin"
          />
          <link rel="icon" href={favicon} />
        </Helmet>
        <audio
          ref={soundMusic => {
            this.soundMusic = soundMusic
          }}
        >
          <source src={musicSrc} type="audio/mp3" />
        </audio>
        <audio
          ref={soundWin => {
            this.soundWin = soundWin
          }}
        >
          <source src={sfxWin} type="audio/wav" />
        </audio>
        <audio
          ref={soundNew => {
            this.soundNew = soundNew
          }}
        >
          <source src={sfxNew} type="audio/wav" />
        </audio>
        <Menu
          locale={locale}
          menuFor={'stack'}
          soundFn={this.handleToggleMute}
          sound={this.state.soundstatus}
          musicFn={this.handleToggleMusic}
          musicStatus={this.state.musicStatus}
        />
        {children}
        <Footer />
      </Wrapper>
    )
  }
}

export default StackLayout
