// @flow

import palette from './colors'

import heroBg from 'assets/satoshi-dice-header-transparent.png'
import siteLogo from 'assets/satoshidice-logo.png'

import checkmark from 'assets/ico-step3.png'

import Step1 from 'assets/ico-step1.png'
import Step2 from 'assets/ico-step2.png'
import Step3 from 'assets/ico-step3.png'

import qrLogo from 'assets/sdlogo.png'

export const mainTheme = {
    // main
    background: palette.white,
    foreground: palette.mineShaft,
    highlight: palette.charlie,
    shadow: palette.muted,
    
    // highlights
    primary: palette.sulu,
    secondary: palette.darkSea,
    tertiary: palette.cascade,
    
    // table colors    
    rowBackground: palette.fanny,
    rowBorder: palette.faded,

    // gradient colors
    gradientStart: palette.fuchsiaBlue,
    gradientEnd: palette.shamrock,

    // hero background image and site logo
    heroBg: heroBg,
    siteLogo: siteLogo,

    // other site images
    checkmark: checkmark,
    qrlogo: qrLogo,
    step1: Step1,
    step2: Step2,
    step3: Step3
}