import { DEFAULT_USD_VALUE } from '../settings'

export const getSelectedAddress = state => {
  let { selectedAddress, gamerules } = state.meta

  if (!gamerules || !gamerules.orderedOdds) return selectedAddress

  if (!selectedAddress) {
    const middle = Math.floor(gamerules.orderedOdds.length / 2)
    selectedAddress = gamerules.orderedOdds[middle].address
  }

  return selectedAddress
}
export const getSelectedValue = state => {
  let selectedValue = state.meta.selectedValue
  if (!selectedValue) {
    selectedValue = (DEFAULT_USD_VALUE / state.prices.USD).toFixed(8)
  }
  return selectedValue
}
export const getMusicStatus = state => state.meta.soundMusic
export const getSfxStatus = state => state.meta.soundSFX
export const getTxids = state => state.meta.txids
