import styled from 'styled-components'
import { media } from 'styles/utils'
import Text from 'atoms/Text'

export const Wrapper = styled.div`
  text-align: center;

  justify-content: center;
  color: ${props => props.theme.background};
  padding: 10px;
  ${media.large`
      order: 2;
  `}
`

export const Step = styled.div`
  display: grid;
  grid-template-areas: 'icon title title' 'icon info info';
  text-align: left;
  grid-gap: 10px;
`

export const Image = styled.img`
  grid-area: icon;
`

export const StepTitle = styled.h4`
  padding: 0;
  line-height: 0;
  margin-bottom: 5px;
  font-size: 20px;
  margin-top: 15px;
  grid-area: title;
  color: ${props => props.theme.primary};
`

export const StepText = styled(Text)`
  margin: 0;
  grid-area: info;
  margin-bottom: 20px;
  color: ${props => props.theme.background};
  line-height: 1.5;
`

export const Strong = styled.strong`
  color: ${props => props.theme.highlight};
`
