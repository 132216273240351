import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  background: black;
  color: white;
  left: 0px;
  width: auto;
  right: 0px;
  z-index: 100;
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid gray;
  padding: 10px;
  opacity: 0.7;
`

export const MiniWrapper = styled.div`
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 100;
`

export const Notify = styled.div`
  color: ${props =>
    props.win ? props.theme.background : props.theme.foreground};
  background-color: ${props => (props.win ? props.theme.secondary : '#F9CC9D')};
  font-weight: 700;
  text-align: center;
  border-radius: 5px;
  padding: 10px;
  max-width: 300px;
  width: 100%;
  opacity: 0.9;
  margin-top: 10px;
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  grid-template-areas: 'icon win close' 'content content content';
  grid-row-gap: 10px;
`

export const Close = styled.div`
  text-align: right;
  grid-area: close;
  cursor: pointer;
`

export const Icon = styled.div`
  grid-area: icon;
`
