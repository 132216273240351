import styled, { css } from 'styled-components'
import { media } from 'styles/utils'

export const Wrapper = styled.div`
  display: flex;
  margin: auto 0;
  transition: none;
  ${media.large`
  margin-right: 15px;
`}
`

export const Button = styled('div')`
  color: ${props => props.theme.foreground};
  font-weight: 700;
  text-shadow: none;
  background-color: ${props => props.theme.background};
  border: 0.05rem solid #fff;
  border-radius: 35px;
  cursor: pointer;
  padding: 10px;
  transition: none;
  &:hover {
    background-color: ${props => props.theme.rowBorder};
  }
`

export const Content = styled.div`
  position: fixed;
  border-radius: 10px;
  left: 10px;
  right: 10px;
  top: 10px;
  z-index: 9999;
  margin: auto;
  max-width: 450px;
  background: ${props => props.theme.foreground};

  ${props =>
    props.show
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`

export const CloseButton = styled.div`
  cursor: pointer;
  color: ${props => props.theme.foreground};
  background-color: ${props => props.theme.background};
  padding: 10px;
  border-radius: 35px;
  font-weight: 700;
  margin-left: 10px;
  margin-top: 10px;
  margin-right: auto;
  text-align: center;
  width: 80px;
  &:hover {
    background-color: ${props => props.theme.rowBorder};
  }
`
