import styled from 'styled-components'

export const Wrapper = styled.div`
background-color: ${props => props.theme.foreground};
justify-content: center;

`
export const Content = styled.div`
margin:auto;
color: ${props => props.theme.background};
text-align: center;
padding: 10px;
`

export const Logo = styled.img`
max-height: 60px;
max-width: 250px;
`

export const Copyright = styled.div`
font-size: 14px;
`
export const Contact = styled.div`
color: ${props => props.theme.background};
font-size: 14px;
padding: 10px;

`

export const SocialLinks = styled.div`
padding: 10px;
`
