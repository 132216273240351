// @flow

import styled, { css } from 'styled-components'

import palette from 'styles/colors'

const H1 = styled.h1`
    color: ${palette.white};
    font-size:24px;
    letter-spacing:1px;

    ${props => props.inverse && css`
        color: ${palette.foreground}
    `}

    ${props => props.tight && css`
        line-height:1;
        margin-bottom:0;
        color: ${palette.foreground};
    `}

    ${props=> props.thin && css`
        font-weight:400;
    `}
`

export default H1