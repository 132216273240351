import {
  SET_SELECTED_ADDRESS,
  SET_SELECTED_VALUE,
  TOGGLE_SOUND_MUSIC,
  TOGGLE_SOUND_SFX,
  ADD_TRANSACTION_ID,
} from './constants'

export const setSelectedAddress = address => {
  return {
    type: SET_SELECTED_ADDRESS,
    payload: address,
  }
}

export const setSelectedValue = value => {
  return {
    type: SET_SELECTED_VALUE,
    payload: value,
  }
}

export const toggleSoundMusic = () => {
  return {
    type: TOGGLE_SOUND_MUSIC,
  }
}

export const toggleSoundSFX = () => {
  return { type: TOGGLE_SOUND_SFX }
}

export const addTransactionId = txid => {
  return { type: ADD_TRANSACTION_ID, payload: txid }
}
