// @flow

import React from 'react'
import { withTheme } from 'styled-components'
import { injectIntl, FormattedMessage } from 'react-intl'
import Link from 'gatsby-link'
import { IconTwitter } from '../../atoms/Icons'
import {
  Wrapper,
  Content,
  Logo,
  Copyright,
  Contact,
  SocialLinks,
} from './styled'

class Footer extends React.Component {
  render() {
    const { intl, theme } = this.props
    const { messages } = intl
    const link = (
      <a href={`mailto:${messages['contact-email']}`}>
        {messages['contact-email']}
      </a>
    )
    return (
      <Wrapper>
        <Content>
          <SocialLinks>
            <a
              href="https://twitter.com/SatoshiDICE"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconTwitter />
            </a>
          </SocialLinks>
          <Link to="/">
            <Logo src={theme.siteLogo} alt="SatoshiDICE" />
          </Link>
          <Copyright>{messages.Footer.copyright}</Copyright>
          <Contact>
            <FormattedMessage
              id="Footer.contact"
              defaultMessage={messages.Footer.contact}
              values={{ email: link }}
            />
          </Contact>
        </Content>
      </Wrapper>
    )
  }
}

export default withTheme(injectIntl(Footer))
