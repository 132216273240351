// @flow

import styled, {css} from 'styled-components'

import palette from 'styles/colors'

const Text = styled.p`
    color: ${palette.white};
    font-weight: 400;
    line-height:1.5;
    font-size:16px;
    margin: 0;
    ${props => props.bold && css`
        font-weight: 700;
    `}
    margin-bottom: 10px;
    ${props => props.area && css`
        grid-area: ${props.area};
    `}

    ${props => props.color && css`
        color: ${props.color}
    `}
`

export default Text