// @flow

import React from 'react'
import { withTheme } from 'styled-components'
import { injectIntl } from 'react-intl'
import Introduction from 'components/Introduction'
import { Wrapper, Button, Content, CloseButton } from './styled'

class HelpButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
    }
    this.handleShow = this.handleShow.bind(this)
  }

  handleShow() {
    this.setState({
      show: !this.state.show,
    })
  }
  render() {
    const {
      top,
      intl: { messages },
    } = this.props
    return (
      <Wrapper top={top}>
        <Button onClick={this.handleShow}>
          {messages.Information['how-to']}
        </Button>
        <Content show={this.state.show}>
          <CloseButton onClick={this.handleShow}>
            {messages.Information.close}
          </CloseButton>
          <Introduction />
        </Content>
      </Wrapper>
    )
  }
}

export default withTheme(injectIntl(HelpButton))
