// @flow

/*

#8360C3 - hero gradient start
#2EBF91 - hero gradient end
#BBED90 - light green
#8EAAAF - blue grey
#4B5152 - dark grey

 */

import chroma from "chroma-js";

const primary = chroma("#fab915");
const secondary = "blue";

const primaryMuted = primary.alpha(0.075).css();

const muted = chroma("#222")
  .alpha(0.3)
  .css();

export const background = primary.set("hsl.l", 0.99);
const foreground = chroma("#222")
  .alpha(0.8)
  .css();

const white = chroma("#ffffff");

/* package colors */
const mineShaft = chroma('#222');
const mineShaftLight = "#3C3C3C";
const mineShaftDark = "#303030";

const darkSea = chroma('#4b5152');

const codGray = chroma('#191919');

const forestgreen = chroma('forestgreen');

const punch = "#db3434";
const tallPoppy = "#b92929";

const fuchsiaBlue = "#8360C3";
const shamrock = "#2EBF91";
const sulu = "#BBED90";
const cascade ="#8EAAAF";
const charlie = "#CCCCCC";
const fanny = "#FAFAFA";
const alabaster = "#F8F8F8";

export default {
  muted,
  primary,
  primaryMuted,
  secondary,
  foreground,
  background,
  faded: chroma(background)
    .darken(0.1)
    .css(),
  white,
  forestgreen,
  punch,
  tallPoppy,
  mineShaft,
  mineShaftLight,
  mineShaftDark,
  darkSea,
  codGray,
  fuchsiaBlue,
  shamrock,
  sulu,
  cascade,
  charlie,
  fanny,
  alabaster
};
