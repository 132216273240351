import {
  FETCH_GAMERULES_SUCCESS,
  FETCH_GAMERULES_BEGIN,
  FETCH_GAMERULES_FAIL,
  INCREMENT_COUNTER,
} from './constants'

import { GAMERULES_URL } from '../api'

const fetchGamerulesBegin = () => {
  return {
    type: FETCH_GAMERULES_BEGIN,
  }
}

const fetchGamerulesSuccess = data => {
  return {
    type: FETCH_GAMERULES_SUCCESS,
    payload: data,
  }
}

const fetchGamerulesFail = () => {
  return {
    type: FETCH_GAMERULES_FAIL,
  }
}

export const incrementCounter = () => {
  return dispatch => {
    dispatch({
      type: INCREMENT_COUNTER,
    })
  }
}

export const fetchGamerules = () => {
  return async dispatch => {
    dispatch(fetchGamerulesBegin())
    try {
      const result = await fetch(GAMERULES_URL)
      const text = await result.text()
      const json = await JSON.parse(text)

      dispatch(fetchGamerulesSuccess(json))
    } catch (error) {
      console.error(error)
      dispatch(fetchGamerulesFail())
    }
  }
}
