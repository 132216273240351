import React from 'react'
import { withTheme } from 'styled-components'

import langs from 'data/locales'
import { injectIntl } from 'react-intl'

import { IconGlobe, IconExpandDown } from 'atoms/Icons'
import { StyledLink, LanguageMenu, SubMenu, SubMenuItem } from './styled'

class Languages extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showLanguages: false,
    }

    this.handleShowLanguages = this.handleShowLanguages.bind(this)
  }
  handleShowLanguages(event) {
    event.preventDefault()
    this.setState({
      showLanguages: !this.state.showLanguages,
    })
  }

  render() {
    const { intl } = this.props
    const currentLocation = window.location.pathname.split('/')

    const currLang = langs[intl.locale].locale

    const languages = Object.keys(langs).reduce((prev, curr) => {
      const lang = langs[curr]
      const pack = { path: lang.path, locale: lang.locale }

      return [...prev, pack]
    }, [])
    return (
      <LanguageMenu>
        <StyledLink as="div" onClick={this.handleShowLanguages}>
          <IconGlobe size={19} /> {currLang}
          <IconExpandDown size={19} />
        </StyledLink>
        <SubMenu showMenu={this.state.showLanguages}>
          {languages.map((lang, id) => {
            return (
              <SubMenuItem
                key={lang.path + id}
                to={`/${lang.path === 'en' ? '' : `${lang.path}/`}${
                  currentLocation[currentLocation.length - 1].length !== 2
                    ? currentLocation[currentLocation.length - 1]
                    : ''
                }`}
                onClick={() => {
                  this.setState({
                    showLanguages: !this.state.showLanguages,
                  })
                }}
              >
                {lang.locale}
              </SubMenuItem>
            )
          })}
        </SubMenu>
      </LanguageMenu>
    )
  }
}

export default injectIntl(withTheme(Languages))
