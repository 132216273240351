import React from 'react'
import { IconClose, IconInfo } from 'atoms/Icons'
import { Wrapper, MiniWrapper } from './styled'

class Notification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closed: false,
    }

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose() {
    const { closed } = this.state
    this.setState({ closed: !closed })
  }

  render() {
    const { closed } = this.state
    if (closed)
      return (
        <MiniWrapper onClick={this.handleClose}>
          <IconInfo height="32px" width="32px" fill="lightgrey" />
        </MiniWrapper>
      )
    return (
      <Wrapper>
        <div style={{ width: '100%', textAlign: 'center' }}>
          For the best SatoshiDICE experience, we recommend the{' '}
          <a
            style={{ margin: 'auto' }}
            href="https://wallet.bitcoin.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Bitcoin.com Wallet
          </a>
        </div>
        <div
          onClick={this.handleClose}
          onKeyPress={() => {}}
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer' }}
        >
          <IconClose />
        </div>
      </Wrapper>
    )
  }
}
export default Notification
