import styled, { css } from 'styled-components'
import { media } from 'styles/utils'
import { Link } from 'gatsby'

export const StyledLink = styled(Link)`
  color: ${props => props.theme.background};
  text-decoration: none;
  cursor: pointer;
  padding: 10px 15px;
  font-size: 20px;
  font-weight: 400;
  box-sizing: border-box;
  transition: color 250ms ease-in-out;
  align-self: center;
  &:hover {
    color: ${props => props.theme.tertiary};
  }
`

export const LanguageMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const SubMenu = styled.div`
  ${props =>
    props.showMenu
      ? css`
          max-height: 400px;
        `
      : css`
          max-height: 0px;
        `};
  display: flex;
  flex: 1;
  z-index: 9999;
  flex-direction: column;
  overflow: hidden;
  text-align: center;
  margin-top: 20px;
  top: 40px;
  background: ${props => props.theme.foreground};

  transition: all 0.3s ease-out;
  ${media.large`
      transition: none;
      position: absolute;
  `};
`

export const SubMenuItem = styled(Link)`
  color: ${props => props.theme.background};
  padding: 5px 30px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: ${props => props.theme.shadow};
    color: ${props => props.theme.tertiary};
  }
`
