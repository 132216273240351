// @flow

import React from 'react'
import styled, { css } from 'styled-components'

// Define smallest size for each breakpoint.
// small is same as no media query.
const sizes = {
  large: 1008,
  medium: 641,
  small: 0,
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)};
    }
  `

  return acc
}, {})

const BchBig = styled.span`
  font-weight: 600;
  ${props =>
    props.last &&
    css`
      margin-right: 4px;
    `}
`

const BchSmall = styled.span`
  font-weight: 400;
  font-size: 90%;
  ${props =>
    props.space &&
    css`
      margin-left: 3px;
    `}
  ${props =>
    props.last &&
    css`
      margin-right: 4px;
    `}
`

const formatBCHValue = value => {
  if (value === undefined) return null

  const numArray = value
    .toFixed(8)
    .toString()
    .split('')

  const decimalStart = numArray.length > 10 ? numArray.length - 10 + 4 : 4

  return numArray.map((item, idx) => {
    if (idx < decimalStart) {
      return (
        <BchBig key={idx} last={idx === numArray.length - 1}>
          {item}
        </BchBig>
      )
    } else {
      return (
        <BchSmall
          key={idx}
          space={idx === decimalStart || idx === decimalStart + 3}
          last={idx === numArray.length - 1}
        >
          {item}
        </BchSmall>
      )
    }
  })
}

export { media, formatBCHValue }
