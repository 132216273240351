import React from 'react'
import { withTheme } from 'styled-components'

import { injectIntl, FormattedMessage } from 'react-intl'

import H1 from 'atoms/H1'
import { Wrapper, Step, Image, StepTitle, StepText, Strong } from './styled'

class Introduction extends React.Component {
  render() {
    const { intl, theme } = this.props
    const { messages } = intl
    const bitcoincash = (
      <span style={{ display: 'block' }}>{messages.bitcoincash}</span>
    )
    return (
      <Wrapper>
        <H1 thin style={{ width: '100%' }}>
          <FormattedMessage
            id="Introduction.title"
            defaultMessage={messages.Introduction.title}
            values={{ bitcoincash }}
          />
        </H1>
        <Step>
          <Image src={theme.step1} />
          <StepTitle>{messages.Introduction.step1}</StepTitle>
          <StepText>
            {messages.Introduction.step1text}
            <br />
            <Strong>{messages.Introduction.step1disclaimer}</Strong>
          </StepText>
        </Step>
        <Step>
          <Image src={theme.step2} />
          <StepTitle>{messages.Introduction.step2}</StepTitle>
          <StepText>{messages.Introduction.step2text}</StepText>
        </Step>
        <Step>
          <Image src={theme.step3} />
          <StepTitle>{messages.Introduction.step3}</StepTitle>
          <StepText>{messages.Introduction.step3text}</StepText>
        </Step>
      </Wrapper>
    )
  }
}

export default withTheme(injectIntl(Introduction))
