// @flow

import styled from 'styled-components'

import { media } from 'styles/utils'

const Container = styled.div`
  max-width: 1200px;
  display: flex;
  flex: 1;
  margin: auto;
  flex-wrap: wrap;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  ${media.large`
        padding: 15px;
    `}
`

export default Container
